import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ChakraProvider, VisuallyHidden } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  reportRoot,
  infoRoot,
  mainContentId,
  privacyRoot,
  accessibilityRoot,
  flowAuthCallbackRoot,
  surveyFlowRoot,
  flowPaymentCallbackRoot,
  chatAuthCallbackRoot,
} from '~/src/constants';
import theme from '~/src/theme/theme';
import Header from '~/src/components/Header';
import NoContent from '~/src/screens/404';
import Chat from '~/src/screens/Chat';
import ReportDownload from '~/src/screens/ReportDownload';
import Report from '~/src/screens/Report';
import ReportInstructions from '~/src/screens/ReportInstructions';
import ChatDiscussion from '~/src/screens/ChatDiscussion';
import Home from '~/src/screens/Home';
import InfoModal from '~/src/components/InfoModal';
import Info from '~/src/screens/Info';
import ShortQuestionRedirect from '~/src/components/ShortQuestionRedirect';
import { chat } from '~/src/configurations';
import ScrollToTop from '~/src/utils/ScrollToTop';
import { devlogger, envs } from '~/src/utils';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { HomeYouth } from '~/src/screens/Home/HomeYouth';
import { HomeGuardian } from '~/src/screens/Home/HomeGuardian';
import { ReportViewType } from '~/src/types';
import Privacy from '~/src/screens/Privacy';
import { AccessibilityStatement } from '~src/screens/Accessibility';
import { AuthenticationCallback } from '~src/components/SurveyFlow/Authentication/AuthenticationCallback';
import { SurveyFlow } from '~src/screens/SurveyFlow/SurveyFlow';
import { PaymentCallback } from '../SurveyFlow/Payment/PaymentCallback';
import { DummyTokenProtection } from '../DummyTokenProtection';
import {
  ChatProviderWrapper,
  CHATROOM_URL_PATH,
  CHAT_LOGIN_URL,
} from '~src/chat/chat-context';
import { ChatAuthCallback } from '../Chat/ChatLogin/ChatAuthCallback';

const App = () => {
  const { t } = useTranslation();

  // Log all environment variables on dev startup
  useEffect(() => {
    devlogger(envs);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter {...(envs.BASENAME ? { basename: envs.BASENAME } : {})}>
        <CompatRouter>
          <DummyTokenProtection>
            <ChatProviderWrapper>
              <ScrollToTop />
              <VisuallyHidden as="a" href={`#${mainContentId}`}>
                {t('common.skipToContentLinkText')}
              </VisuallyHidden>

              <Switch>
                {chat.enableChat && (
                  <Route exact path={CHATROOM_URL_PATH + '/:chatRoomId'}>
                    <ChatDiscussion />
                  </Route>
                )}
                <Route>
                  <Header />
                  <main id={mainContentId}>
                    <Switch>
                      {chat.enableChat && (
                        <Route exact path={CHAT_LOGIN_URL} component={Chat} />
                      )}
                      <Route
                        exact
                        path={[
                          `/${surveyFlowRoot}/:name`, // TODO: Check do we need survey :name url?
                          `/${surveyFlowRoot}/:name/:id`, // TODO: Check do we need :name in url?
                        ]}
                        component={SurveyFlow}
                      />

                      <Route
                        exact
                        path={[
                          `/${chatAuthCallbackRoot}/:chatRoomId/:answerId`,
                        ]}
                        component={ChatAuthCallback}
                      />
                      <Route
                        exact
                        path={[`/${flowAuthCallbackRoot}/:flow/:answerId`]}
                        component={AuthenticationCallback}
                      />

                      <Route
                        exact
                        path={`/${flowPaymentCallbackRoot}/:flow/:answerId`}
                        component={PaymentCallback}
                      />

                      <Route
                        exact
                        path={`/${privacyRoot}`}
                        component={Privacy}
                      />
                      <Route
                        exact
                        path={`/${accessibilityRoot}`}
                        component={AccessibilityStatement}
                      />

                      <Route
                        exact
                        path={`/${reportRoot}`}
                        component={ReportDownload}
                      />

                      <Route
                        exact
                        path={`/${reportRoot}/summary`}
                        render={() => <Report view={ReportViewType.Summary} />}
                      />

                      <Route
                        exact
                        path={`/${reportRoot}/summary/print`}
                        render={() => <Report view={ReportViewType.Print} />}
                      />

                      <Route
                        exact
                        path={`/${reportRoot}/full`}
                        render={() => <Report view={ReportViewType.Full} />}
                      />

                      <Route
                        exact
                        path={`/${reportRoot}/pdf`}
                        render={() => <Report view={ReportViewType.Pdf} />}
                      />

                      <Route
                        exact
                        path={`/${reportRoot}/instructions`}
                        render={() => <ReportInstructions />}
                      />

                      <Route exact path={`/${infoRoot}`} component={Info} />

                      {envs.CONFIGURATION === 'Interventionavigaattori' && (
                        <Route exact path={`/nuori`} component={HomeYouth} />
                      )}
                      {envs.CONFIGURATION === 'Interventionavigaattori' && (
                        <Route
                          exact
                          path={`/huoltaja`}
                          component={HomeGuardian}
                        />
                      )}

                      <Route
                        exact
                        path={[`/question/:id`, `/:id`]}
                        component={ShortQuestionRedirect}
                      />

                      <Route exact path={`/`} component={Home} />

                      <Route component={NoContent} />
                    </Switch>
                  </main>
                </Route>
              </Switch>
              <InfoModal />
            </ChatProviderWrapper>
          </DummyTokenProtection>
        </CompatRouter>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
