import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '~/src/components/Container';
import { FlowItemDescription } from '~src/components/SurveyFlow/components/FlowItemDescription';
import { FlowItemTitle } from '~src/components/SurveyFlow/components/FlowItemTitle';
import { Flex } from '@chakra-ui/react';
import { ChatAuthentication } from '~src/components/Chat/ChatLogin/ChatAuthentication';
import { MenuItemAction } from '~src/api';
import { useHandleFlowInitClick } from '~src/hooks/useHandleFlowInitClick';
import { AppContext } from '~src/state';
import Loading from '~src/components/Loading';
import ErrorCard from '~src/components/ErrorCard';
import { useEnterToChatRoom } from '~src/chat/useEnterToChatroom';
import { getSessionStorage } from '~src/utils/storage';
import { ChatContext } from '~src/chat/chat-context';
import { useSetChatRoomId } from '~src/chat/useSetChatRoomId';
import { chatLogger } from '~src/chat/chat-utils';

const Chat = () => {
  useSetChatRoomId();
  const { state, dispatch } = useContext(AppContext);
  const {
    userInfo: { chatRoomId },
  } = useContext(ChatContext);
  const { t } = useTranslation();
  const chatStorage = getSessionStorage('chat');
  const userSessionId = chatStorage?.userSessionId;

  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const { clickLoading, startSurveyError, handleClick } =
    useHandleFlowInitClick({
      redirectToQuestion: false,
    });

  useEffect(() => {
    if (isChatInitialized === false && !userSessionId) {
      dispatch({
        type: 'SET_CURRENT_FLOW_ITEM',
        payload: null,
      });
      setIsChatInitialized(true);
      handleClick(MenuItemAction.Question, 'chat-authentication');
    }
  }, [userSessionId, dispatch, handleClick, isChatInitialized]);

  /** Skip authentication when userSessionId already found */
  const {
    jumpInToRoom,
    createGuestSessionParams: {
      loading: createSessionLoading,
      error: createSessionError,
      data: guestSessionData,
      reset,
    },
  } = useEnterToChatRoom(chatRoomId || '');

  const shouldJumpInToRoom =
    chatRoomId &&
    userSessionId &&
    !guestSessionData &&
    !createSessionLoading &&
    !createSessionError;

  useEffect(() => {
    const wrapperFunc = async () => {
      if (shouldJumpInToRoom) {
        chatLogger('useChat - wrapperFunc: Jumping in to room');
        await jumpInToRoom(userSessionId);
        reset?.();
      }
    };

    wrapperFunc();
  }, [shouldJumpInToRoom, jumpInToRoom, userSessionId, reset]);

  if (userSessionId || clickLoading || createSessionLoading)
    return (
      <Flex justifyContent="center">
        <Loading />
      </Flex>
    );

  const flowResponse = state.currentFlowItem;
  const { id, flowItem } = flowResponse || {};

  if (!id || !flowItem || startSurveyError)
    return (
      <Container paddingTop={[1, 1, 1]} px={[6, 6, 6]}>
        <ErrorCard itemData={state.currentFlowItem} showButton={false} />
      </Container>
    );

  const { title, description } = flowItem || {};

  const firstDescription = description ? [description[0]] : undefined;
  const restDescription = description ? description.slice(1) : undefined;

  return (
    <Container paddingTop={[1, 1, 1]} px={[6, 6, 6]}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap={6}
        mb="32px"
        mx="auto"
        color="primary.500"
        fontWeight={700}
        maxW="500px"
      >
        <FlowItemTitle title={title || t('chat.init.title')} />
        <FlowItemDescription
          textAlign="center"
          description={firstDescription}
        />
      </Flex>
      <ChatAuthentication
        answerId={id}
        flowItem={{
          ...flowItem,
          description: restDescription,
        }}
      />
    </Container>
  );
};

export default Chat;
