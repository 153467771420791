import { ApolloError, MutationResult } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CreateGuestSessionMutation,
  DevGuestSessionParams,
  useCreateGuestSessionMutation,
} from '~src/api';
import {
  getSessionStorage,
  removeSessionStorage,
  upsertSessionStorage,
} from '~src/utils/storage';
import { CHAT_LOGIN_URL_PATH } from './chat-context';
import { chatLogger } from './chat-utils';

export type ChatTokenHook = {
  createGuestSession: (params: {
    variables: {
      body: {
        userSessionId: string;
        devParams: DevGuestSessionParams | undefined;
      };
    };
  }) => void;
  createGuestSessionParams: Partial<MutationResult<CreateGuestSessionMutation>>;
  accessToken: string | null;
  accessTokenExpiresIn: number | null;
  userSessionId: string | null;
  destroyChatSessionStorage: () => void;
};

/**
 * Custom hook to handle chat tokens
 * @param chatRoomId - chat room id
 * @returns ChatTokenHook
 */
const useChatTokens = (chatRoomId?: string): ChatTokenHook => {
  const history = useHistory();
  const chatStorage = getSessionStorage('chat');
  const accessTokenExpiresIn = chatStorage?.expiresIn || null;
  const [userSessionId, setUserSessionId] = useState<string | null>(
    chatStorage?.userSessionId || null,
  );

  const [accessToken, setAccessToken] = useState<string | null>(
    chatStorage?.accessToken || null,
  );

  const [createGuestSession, createGuestSessionParams] =
    useCreateGuestSessionMutation({
      onError(error: ApolloError) {
        chatLogger('useCreateGuestSessionMutation error', error);
        history.push(`${CHAT_LOGIN_URL_PATH}/${chatRoomId}`);
      },
      onCompleted(data, props) {
        chatLogger('D-Chat guest session created successful', data);
        const { userSessionId } = props?.variables?.body || {};
        setUserSessionId(userSessionId);
        setAccessToken(data.createGuestSession?.accessToken);
        upsertSessionStorage('chat', {
          ...chatStorage,
          ...data.createGuestSession,
          userSessionId,
        });
      },
    });

  /**
   * Destroy the chat session storage and set the access token to null
   */
  function destroyChatSessionStorage() {
    removeSessionStorage('chat');
    setAccessToken(null);
    setUserSessionId(null);
  }

  return {
    createGuestSession,
    createGuestSessionParams,
    accessToken,
    accessTokenExpiresIn,
    userSessionId,
    destroyChatSessionStorage,
  };
};

export { useChatTokens };
