const toBoolean = (value: string | undefined) => {
  if (typeof value !== 'string') return undefined;
  return value.toLowerCase() === 'true' || value === '1';
};

// Add trailing slash to server url if not existing already
let serverUrl = process.env.SERVER_URL ?? '';
if (!serverUrl.endsWith('/')) serverUrl += '/';

const envs: {
  GRAPHQL_API_URL: string;
  SERVER_URL: string;
  CONFIGURATION: string;
  FF_ENABLE_CHAT?: boolean;
  FF_ENABLE_PRO_CHAT_VIEW?: boolean;
  FF_START_MENU?: string;
  BASENAME?: string;
  SITE_TITLE?: string;
  FEEDBACK_ID: string;
  UPCOMING_MAINTENANCE_COMPLETED_AT: string;
  SITE_TOKEN_PROTECTION_ENABLED?: boolean;
  CHAT_WEBSOCKET_URL?: string;
} = {
  GRAPHQL_API_URL: process.env.GRAPHQL_API_URL ?? '',
  SERVER_URL: serverUrl,
  CONFIGURATION:
    process.env.CONFIGURATION ?? process.env.CLIENT_ORGANIZATION ?? '',
  FF_ENABLE_CHAT: toBoolean(process.env.FF_ENABLE_CHAT),
  FF_ENABLE_PRO_CHAT_VIEW: toBoolean(process.env.FF_ENABLE_PRO_CHAT_VIEW),
  FF_START_MENU: process.env.FF_START_MENU,
  BASENAME: process.env.BASENAME,
  FEEDBACK_ID: process.env.FEEDBACK_ID || 'terapia',
  SITE_TITLE: process.env.SITE_TITLE,
  UPCOMING_MAINTENANCE_COMPLETED_AT:
    process.env.UPCOMING_MAINTENANCE_COMPLETED_AT ?? '',
  SITE_TOKEN_PROTECTION_ENABLED: toBoolean(
    process.env.SITE_TOKEN_PROTECTION_ENABLED,
  ),
  CHAT_WEBSOCKET_URL: process.env.CHAT_WEBSOCKET_URL,
};

export default envs;
